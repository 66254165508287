<template>
  <div>
    <v-file-input
      v-model="mainImage"
      accept="image/*"
      label="Izaberite glavnu sliku"
      prepend-icon="mdi-camera"
      color="#137547"
      chips
      show-size
      :error-messages="mainImageErrorMessage"
      @change="mainImageChanged"
      @click:clear="clearMainImage"
    ></v-file-input>
    <div class="preview">
      <img v-if="url.mainImage" :src="url.mainImage" />
    </div>
    <v-file-input
      v-model="otherImages"
      accept="image/*"
      label="Izaberite ostale slike (opcionalno)"
      prepend-icon="mdi-camera"
      color="#137547"
      chips
      counter
      max="15"
      multiple
      show-size
      :error-messages="otherImagesErrorMessage"
      @change="otherImagesChanged"
      truncate-length="15"
      @click:clear="clearOtherImages"
    ></v-file-input>
    <v-carousel v-if="url.otherImages.length > 0">
      <v-carousel-item v-for="(image, i) in url.otherImages" :key="i" :src="image"></v-carousel-item>
    </v-carousel>
    <v-card-actions class="nextStepButton">
      <v-btn color="#fff" @click="goBack">
        <p style="margin: auto; color: #137547">NAZAD</p>
      </v-btn>
      <v-btn color="#137547" @click="goToSubmit">
        <p style="margin: auto; color: #ffff">SLEDEĆI KORAK</p>
      </v-btn>
    </v-card-actions>
    <v-card-text class="errorMessage">{{ errorMessage }}</v-card-text>
    <v-dialog v-model="dialog" persistent max-width="450">
      <v-card>
        <v-card-title class="text-h5" style="text-align: center; word-break: break-word">Da li želite da postavite oglas?</v-card-title>
        <v-card-actions class="justify-center">
          <v-btn color="error" text class="mr-2" @click="dialog = false"> Odustani </v-btn>
          <v-btn color="success" @click="submitForm"> Postavi oglas </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "ProductImages",
  data() {
    return {
      mainImage: null,
      otherImages: [],
      mainImageErrorMessage: "",
      otherImagesErrorMessage: "",
      errorMessage: "",
      url: {
        mainImage: null,
        otherImages: [],
      },
      dialog: false,
    };
  },
  methods: {
    mainImageChanged(e) {
      if (e === null) {
        return;
      }
      if (e?.type !== "image/jpg" && e?.type !== "image/jpeg" && e?.type !== "image/png") {
        this.mainImageErrorMessage = "Dozvoljeni su samo jpg, jpeg i png formati slike.";
        this.mainImage = null;
      } else if (e.size > 3000000) {
        this.mainImageErrorMessage = "Maksimalna velicina slike je 3 MB!";
        this.mainImage = null;
      } else {
        this.mainImage = e;
        this.url.mainImage = URL.createObjectURL(e);
      }
    },
    otherImagesChanged(e) {
      let temp = [];
      if (e?.length > 15) {
        this.otherImagesErrorMessage = "Maksimalan broj slika koje možete dodati je 10.";
      }
      for (let i = 0; i < e.length; i++) {
        if (e[i]?.type !== "image/jpg" && e[i]?.type !== "image/jpeg" && e[i]?.type !== "image/png") {
          this.otherImagesErrorMessage = "Dozvoljeni su samo jpg, jpeg i png formati slike.";
        } else if (e.size > 3000000) {
          this.otherImagesErrorMessage = "Maksimalna velicina slike je 3 MB!";
        } else {
          let tempObject = URL.createObjectURL(e[i]);
          temp.push(tempObject);
        }
      }
      this.url.otherImages = temp;
    },
    clearMainImage() {
      this.mainImageErrorMessage = "";
      this.mainImage = null;
      this.url.mainImage = null;
    },
    clearOtherImages() {
      this.otherImagesErrorMessage = "";
      this.otherImages = [];
      this.url.otherImages = [];
    },
    ...mapActions(["RemoveProduct"]),
    async removeProductFromVuex() {
      await this.RemoveProduct();
    },
    ...mapActions(["AddProductImages"]),
    async addToVuex() {
      let tempObject = {
        mainImage: this.mainImage,
        mainImageUrl: this.url.mainImage,
        otherImages: this.otherImages,
        otherImagesUrl: this.url.otherImages,
      };
      await this.AddProductImages(tempObject);
    },
    async submitForm() {
      this.loading = true;
      let postUrl = "/Product";
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      const FormData = require("form-data");
      let formData = new FormData();

      formData.append("Title", this.currentProduct.name);
      formData.append("Description", this.currentProduct.description);
      formData.append("SubCategoryId", this.currentProduct.subcategory);
      formData.append("IsPriceFixed", this.currentProduct.isPriceFixed);
      formData.append("PaymentOption", this.currentProduct.otherPaymentOption);
      formData.append("IsExchangePossible", this.currentProduct.isExchangePossible);
      if (this.currentProduct.otherPaymentOption > 0) {
        formData.append("Price", 0);
      } else {
        formData.append("Price", this.currentProduct.price);
      }
      if (this.currentProduct.mainImage != null) {
        formData.append("MainImgFile", this.currentProduct.mainImage);
      } else {
        if (this.currentProduct.otherImagesUrl.length > 0) {
          formData.append("MainImgFile", this.currentProduct.otherImages[0]);
          this.currentProduct.otherImagesUrl = [];
        }
      }
      if (this.currentProduct.otherImagesUrl.length > 0) {
        this.currentProduct.otherImages.forEach((el) => {
          formData.append("SecondaryImgFiles", el);
        });
      }
      if (this.currentProduct.useMyPhoneNum) {
        formData.append("PhoneNumber", this.currentLoggedUser.phoneNumber);
      } else {
        formData.append("PhoneNumber", this.currentProduct.phoneNumber);
      }
      if (this.currentProduct.useMyLocation) {
        formData.append("Location", this.currentLoggedUser.location);
      } else {
        formData.append("Location", this.currentProduct.location);
      }

      await axios
        .post(postUrl, formData, config)
        .then((response) => {
          this.loading = false;
          if (response.status === 200) {
            this.removeProductFromVuex();
            this.dialog = false;
            this.$emit("goToNext");
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialog = false;

          if (error.response.data == "Already has the same product listed.") {
            this.errorMessage = "Već postoji Vaš oglas sa ovim nazivom.";
          } else {
            this.errorMessage = "Došlo je do greške. Pokušajte ponovo kasnije.";
          }
        });
    },
    goToSubmit() {
      if (this.mainImageErrorMessage.length < 1 && this.otherImagesErrorMessage.length < 1) {
        this.addToVuex();
        this.dialog = true;
      } else return;
    },
    goBack() {
      this.addToVuex();
      this.$emit("goToPrevious");
    },
  },
  computed: {
    currentLoggedUser() {
      return this.$store.getters.StateCurrentUser;
    },
    currentProduct() {
      return this.$store.getters.StateNewProduct;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.mainImage = this.currentProduct.mainImage;
      this.url.mainImage = this.currentProduct.mainImageUrl;
      this.otherImages = this.currentProduct.otherImages;
      this.url.otherImages = this.currentProduct.otherImagesUrl;
    });
  },
};
</script>

<style lang="scss" scoped>
.preview img {
  max-width: 668px !important;
}
.v-carousel {
  max-width: 668px !important;
}
.preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview img {
  max-width: 100%;
  max-height: 500px;
}

.nextStepButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorMessage {
  text-align: center;
  color: red;
}

.v-card__title {
  justify-content: center;
}
</style>
